<template>
    <div class="pd20x">
        <editClassSet :subjectList="subjectList" :data="currentItem" :visible="visible"></editClassSet>
        <div class="filter" style="padding:15px 0">
            <div> 课程包数目（5条）</div>
            <div>
                <label>搜索：</label>
                <a-select v-model="selectVal" style="width: 120px" >
                    <a-select-option :value="item.val" v-for="(item,index) in option" :key="index">
                        {{item.name}}
                    </a-select-option>
                </a-select>
                <a-input-search placeholder="请输入搜索内容" v-model="searchIpt" class="mr20px" style="width:300px" v-if="selectVal != 4 && selectVal != 5"/>
                <div style="display:inline-block;width:300px" v-else>
                    <a-date-picker @change="onChange" class="mr20px" style="width:200px" >
                    </a-date-picker>
                    <a-button type="primary" @click="search">搜索</a-button>
                </div>
                <a-button type="primary" style="background:#70b2e2;border-color:#70b2e2">一键设置学习基数</a-button>
            </div>
        </div>
         <a-table
            :columns="columns"
            :data-source="data"
            :rowKey="'id'"
        >
            <!-- <template slot="age" slot-scope="text">
                <a-select :defaultValue="text" style="width: 120px" >
                    <a-select-option v-for="item in subjectList" :key="item.id" :value="item.id">
                        {{item.name}}
                    </a-select-option>
                </a-select>
            </template> -->
            <!-- <template slot="status" slot-scope="text">
                <a-date-picker :defaultValue="momentDay(text)" @change="onChange" style="width:130px" />
            </template>
            <template slot="content2" slot-scope="text,record">
                <a-input :defaultValue="text" style="width: 130px" @blur="changeNum(e,record)"></a-input>
            </template> -->
            <template slot="operation" slot-scope="text,record">
               <span class="csP" style="color:#7ebae5" @click="visible = true; currentItem = record">编辑</span>
            </template>
        </a-table>
    </div>
</template>
<script>
const columns = [
  {
    title: '课程包名称',
    dataIndex: 'name',
    align: 'center'
  }, {
    title: '分类',
    dataIndex: 'age',
    align: 'center'
  }, {
    title: '所含科目',
    dataIndex: 'wechart',
    align: 'center'
  }, {
    title: '考试时间',
    dataIndex: 'status',
    align: 'center'
  }, {
    title: '有效时间',
    dataIndex: 'content4',
    align: 'center'
  }, {
    title: '原价（元）',
    dataIndex: 'content5',
    align: 'center'
  }, {
    title: '现价（元）',
    dataIndex: 'content6',
    align: 'center'
  }, {
    title: '学习次数',
    dataIndex: 'content2',
    scopedSlots: { customRender: 'content2' },
    align: 'center'
  }, {
    title: '学习基数',
    dataIndex: 'content3',
    align: 'center'
  },{
    title: '操作',
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' },
    align: 'center'
  }
];
import * as http from '@/libs/examapi'
import { momentDay, formatDay } from '@/libs/moment'
import  editClassSet  from './editClassSet'
export default {
    name: 'subjectSet',
    components: { editClassSet },
    data(){
        this.option = [
            { name: '课程包名称', val: '1'},
            { name: '分类', val: '2'},
            { name: '所含科目', val: '3'},
            { name: '考试时间', val: '4'},
            { name: '有效时间', val: '5'}
        ]
        return{
            selectVal: '1',
            searchIpt:'',
            subjectList:[],
            columns,
            data:[
                {
                    id:1,
                    name:'1',
                    age: 8,
                    wechart:'3',
                    status:'2020-5-12',
                    content1:'5',
                    content2:6,
                    content3:7,
                    content4:'5',
                    content5:6,
                    content6:7
                }
            ],
            visible: false,
            currentItem: null

        }
    },
    mounted(){
        this.$nextTick(()=>{
            this.getCates()
        })   
    },
    methods:{
        momentDay,
        getCates(){
            http.get_cate().then(res=>{
                res.forEach(el=>{
                    this.subjectList.push(...el.son)
                })
                this.subjectList = this.subjectList.map(el=>({
                    id: el.id,
                    name: el.name
                }))
                // this.radioList = res.map(el=>({
                //     id: el.id,
                //     name: el.name
                // }))
                // this.radioList.unshift({id:-1,name:'全部'})
            }).catch(error=>{
                console.log(error)
            })
        },
        onChange(date){
            this.dateTime = date
        },
        search(){
        },
        changeNum(e,data){
            console.log(e)
        }

    }
}
</script>
